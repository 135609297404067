import React, {useEffect, useState, useCallback} from "react";
import "swiper/swiper-bundle.css"
import 'video-react/dist/video-react.css';
import '../css/css.css';
import {screenRouter, navigationRouter} from "../router";
import URI from "urijs";
import general, {redux} from "./../general";
import {NavLink, NavView} from "../lib/Navigation";
import {useLocalStorageState, useMount, useTimeout, useLockFn} from "ahooks";
import ActiveContext from "../lib/ActiveContext";
import useActiveState from "../lib/useActiveState";
import $ from "jquery";
import {state} from "../lib/reducer";
import {useSelector} from "react-redux";
import Dialog from "../lib/Dialog";
import Image from "../lib/Image";
import {Link, useHistory, useLocation} from "react-router-dom";
import useUrlState from "@ahooksjs/use-url-state";
import moment from "moment";
import MobileDetect from "mobile-detect";
import Wechat, { inWechat } from './wechat/Index';
import { Badge } from "antd-mobile";
import Mask from "./mask/Mask";
import Index from "./account/alert/Index";
import Manager from "./components/Manager";
import Recommend from "./components/recommend/Recommend";

const mobileDetect = new MobileDetect(navigator.userAgent);

export default () => {
  let history = useHistory();
  let location = useLocation();
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const [unread,setUnread] = useState(0)

  redux.subscribe(()=>{
    setUnread(redux.getState().unread > 99 ? '99+' : redux.getState().unread)
  })

  useMount(() => {
    general.history = history;
    general.location = location;
  });

  const [active, setActive] = useLocalStorageState('active', []);
  const {aplus_queue} = window;

  const {theme} = useSelector(state);
  let [agent, setAgent] = useActiveState('agent');
  let [udid, setUdid] = useActiveState('udid');
  let [key, setKey] = useActiveState('key');
  let [app, setApp] = useActiveState('app');
  let [copyright, setCopyright] = useActiveState('copyright');
  let [tk, setTk] = useActiveState('tk');
  let [tkUrl, setTkUrl] = useActiveState('tkUrl');
  let [bdvid, setBdvid] = useActiveState('bdvid');
  let [extend, setExtend] = useActiveState('extend');
  const [tkStatus, setTkStatus] = useLocalStorageState('register-tk-status', 0);
  const [vestBag, setVestBag] = useLocalStorageState('is-vest-bag', 0);
  const [toDayNews, setToDayNews] = useLocalStorageState('to-day-news-time', 0);
  let [webkitStatus, setWebkitStatus] = useState(true);
  const[indexQueryState, setIndexQueryState] = useState(false);
  const [sdktheme, setSdktheme] = useState(false);
  let [mb, setMb] = useActiveState('mb');
  const [user, setUser] = useActiveState('user');
  
  useMount(() => {
    Manager.init()
    let testAgent = 'rr2f';
    let method =  window.location.protocol.replace(':','');
    if(window.location.host !== 'webbox.99maiyou.cn' && window.location.host !== 'web.99maiyou.cn' && window.location.host !== 'web.milu.com') {
      // general.axios.get('https://apiagent.mlaps0705.com/admin/admin/queryAgentInfo?host=037w.99maiyou.com', {})
      // general.axios.get('https://apiagent.mlaps0705.com/admin/admin/queryAgentInfo?host=32nn.99maiyou.com', {})
      general.axios.get('https://apiagent.mlaps0705.com/admin/admin/queryAgentInfo?host=' + window.location.host + '&source=1', {})
        .then(res => {
          let data = res.data;
          
          if(data.code == 1) {
            testAgent = data.data.agent??testAgent;
          }
          setCopyright(data.data.copyright??'');
          setAgent(agent = testAgent);
          
          if(testAgent == 'rr2f') {
            // temporaryAdjuct();
          }

          load();
          setIndexQueryState(true);
        })
        .catch(err => {
          Dialog.error(err.message);
        });

    } else {
      params.agent && setAgent(agent = params.agent);
      if(window.location.href.indexOf('amp;agent') != '-1') {
        let readurl = window.location.href.replace(/amp;agent/, "agent");
        window.location.href = readurl;
      }

      if(window.location.href.indexOf('amp%3Bagent') != '-1') {
        let readurl = window.location.href.replace(/amp%3Bagent/, "agent");
        window.location.href = readurl;
      }

      load();
      setIndexQueryState(true);
      if(params.agent) {
        if(params.agent == 'rr2f') {
          // temporaryAdjuct();
        }
      } else {
        if(agent == 'rr2f') {
          // temporaryAdjuct();
        }
      }
    }
    urlLogin();
  });

  //查询渠道号
  // const channelQuery = () => {
  //   let testAgent = 'rr2f';
  //   let method =  window.location.protocol.replace(':','');
  //   if(window.location.host !== 'webbox.99maiyou.cn' && window.location.hostname !== 'localhost' && window.location.origin !== 'http://agent.mlaps0705.com' ) {
  //     general.axios.get('https://apiagent.mlaps0705.com/admin/admin/queryAgentInfo?host=' + window.location.host + '&source=1', {})
  //       .then(res => {
  //         let data = res.data;
          
  //         if(data.code == 1) {
  //           testAgent = data.data.agent??testAgent;
  //         }
  //         setCopyright(data.data.copyright??'');
  //         setAgent(agent = testAgent);
  //       })
  //       .catch(err => {
  //         setAgent(agent = testAgent);
  //         Dialog.error(err.message);
  //       });
  //       load();
  //   } else {
  //     params.agent && setAgent(agent = params.agent);
  //     load();
  //   }
  // }
  
  //当组件挂载时执行
  // useMount(async ()=>{
  //   channelQuery();
  // })

  //当agent改变时执行
  // useEffect(()=>{
  //   channelQuery();
  // }, [agent])

 
  //获取agent相关信息
  const load = useLockFn(async () => {
    params.udid && setUdid(udid = params.udid);
    params.key && setKey(key = params.key);
    // console.log(agent);
    // if(params.agent_status == '3b8n') {
    //   Dialog.log(params.key);
    //   Dialog.log(key);
    // }
    console.log('agent',agent);
    if (!key || app.agent !== agent) {
      await general.axios.post('/setKeyUdid', {headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        },agent})
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          if(params.agent_status != '3b8n') { 
            setKey(key = data.key);
            setParams({...params, key: data.key});
          }
        })
    }

    if (key && (app.key !== key || !app.udid)) {
      general.axios.post('/getKeyUdid', {headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        },key})
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          
          if(data.is_ban == 2) {
            setWebkitStatus(false);
            // Dialog.info("链接已被封禁,请联系客服人员！");
            return;
          }
          setApp(app = {...app, ...data});
          if (data.udid) {
            setUdid(udid = data.udid);
          }
        })
    }

    if (key) {
      aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['openid', key]
      });
    }
  })
  // console.log(webkitStatus);
  // console.log(agent);
  // useMount(async () => {
  //   params.udid && setUdid(udid = params.udid);
  //   params.key && setKey(key = params.key);
  //   // console.log(agent);

  //   if (!key || app.agent !== agent) {
  //     await general.axios.post('/setKeyUdid', {agent})
  //       .then(res => {
  //         let {status, data} = res.data;
  //         if (!status.succeed) {
  //           Dialog.error(status.error_desc);
  //           return;
  //         }
  //         setKey(key = data.key);
  //         setParams({...params, key: data.key});
  //       })
  //       .catch(err => {
  //         Dialog.error(err.message);
  //       });
  //   }

  //   if (key && (app.key !== key || !app.udid)) {
  //     general.axios.post('/getKeyUdid', {key})
  //       .then(res => {
  //         let {status, data} = res.data;
  //         if (!status.succeed) {
  //           Dialog.error(status.error_desc);
  //           return;
  //         }
  //         setApp(app = {...app, ...data});
  //         if (data.udid) {
  //           setUdid(udid = data.udid);
  //         }
  //       })
  //       .catch(err => {
  //         Dialog.error(err.message);
  //       });
  //   }

  //   if (key) {
  //     aplus_queue.push({
  //       action: 'aplus.setMetaInfo',
  //       arguments: ['openid', key]
  //     });
  //   }
  // });
  const urlLogin = () => {
    if (params.userid && params.username && params.token) {
      if (window.navigator.standalone ** localStorage.getItem('logout')) {
        return;
      }
      localStorage.setItem('user_id', params.userid);
      localStorage.setItem('username', params.username);
      localStorage.setItem('token', params.token);
      load_load()
    }
  }

  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })

  useMount(urlLogin);
  useEffect(() => {
    history.action !== 'POP' && urlLogin();
  }, [params, history.action]);
  useEffect(() => {
    params.udid && setUdid(params.udid);
  }, [params.udid]);
  useEffect(() => {
    if(tkStatus == 0) {
      params.tk && setTk(params.tk);
    }
  }, [params.tk]);
  useEffect(() => {
    if(tkStatus == 0) {
      params.tk_url && setTkUrl(params.tk_url);
    }
  }, [params.tk_url]);
  useEffect(() => {
    params.bd_vid && setBdvid(params.bd_vid);
  }, [params.bd_vid]);
  
  useEffect(() => {
    params.vb && setVestBag(params.vb);
  }, [params.vb]);

  useEffect(() => {
    params.d && setExtend(params.d);
  }, [params.d]);

  useEffect(() => {
    params.sdktheme && setSdktheme(true);
  }, [params.sdktheme]);

  useEffect(() => {
    params.mb && setMb(params.mb);
  }, [params.mb]);

  useEffect(()=>{
    //激活 还是啥？
    if(params.h && toDayNews == 0) {
      let data = {};
      data.os = 1;
      data.timestamp = moment().valueOf();
      data.ua = navigator.userAgent;
      data.agent = agent;
      data.udid = udid;
      general.axios.get('https://api.data.kaifumao.com/base/Index/getToDayNews?os=1&timestamp=' +  moment().valueOf() + '&ua=' + navigator.userAgent + '&agent=' + agent + '&udid=' + udid + '&bd_vid=' + params.bd_vid, data)
      .then(res => {
        setToDayNews(1);
      })
      .catch(err => {
        Dialog.error(err.message);
      });

      general.axios.post('https://api3.app.wakaifu.com/base/tool/adddata')
      .then(res => {
        // setToDayNews(1);
      })
      .catch(err => {
        // Dialog.error(err.message);
      });
    }
  }, [params.h]);

  // console.log(vestBag);
  useEffect(() => {
    document.title = app.appname;
    $('meta[name=apple-mobile-web-app-title]').attr('content', app.appname);
    $('link[id=appleTouchIcon]').attr('href', app.appicon);
    if (app.flash_screen && app.appname !== '咪噜游戏') {
      [
        {width: 2048, height: 2732},
        {width: 1668, height: 2388},
        {width: 1536, height: 2048},
        {width: 1668, height: 2224},
        {width: 1620, height: 2160},
        {width: 1284, height: 2778},
        {width: 1290, height: 2796},
        {width: 1179, height: 2556},
        {width: 1170, height: 2532},
        {width: 1125, height: 2436},
        {width: 1242, height: 2688},
        {width: 1080, height: 2340},
        {width: 828, height: 1792},
        {width: 1242, height: 2208},
        {width: 750, height: 1334},
        {width: 640, height: 1136},
      ].forEach(({width, height}) => {
        $('link[id="' + (width + '-' + height) + '"]').attr('href', app.flash_screen + (
          width === 1170 && height === 2532 ? '!iphone12pro' : ('!iphone_' + width + 'x' + height)
        ));
      });
    }
    // navConfig();
  }, [app]);

  const [config, setConfig] = useActiveState('config');
  const [newNavLists, setNewNavLists] = useLocalStorageState('index-navlists', []);
  useMount(() => {
    // general.axios.get('/base/common/getConfig')
    //   .then(res => {
    //     let {status, data} = res.data;
    //     if (status.succeed) {
    //       setConfig({...config, ...data});

    //       let arr1 = [];
    //       for(let i = 0; i < data.navList.length; i++){
    //         arr1.push(data.navList[i]);
    //       }

    //       setNewNavLists(arr1);
    //     }
    //   })
    //   .catch(err => {
    //     Dialog.error(err.message);
    //   });
  });

  //config
  const navConfig = () => {
    general.axios.post('/base/common/getConfig')
      .then(res => {
        let {status, data} = res.data;
        if (status.succeed) {
          setConfig({...config, ...data});

          let arr1 = [];
          for(let i = 0; i < data.navList.length; i++){
            arr1.push(data.navList[i]);
          }
          
          setNewNavLists(arr1);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  
  useEffect(()=>{
    navConfig();
  }, [agent]);

  const [navs, setNavs] = useState([]);
  const [action, setAction] = useState([]);
  const handleNavs = () => {
    if (general.callbacking) {
      return;
    }
    let uri = URI(window.location.hash.substring(1));
    setNavs(_navs => {
      let navs = [..._navs];
      let url = uri.pathname() + URI(uri.search()).removeQuery('callback').toString();
      let pathname = uri.pathname();
      // console.log(general.callbacking, general.callback);
      let router = navigationRouter.find(item => item.path === pathname);
      if (router) {
        navs.reverse();
        let index = navs.findIndex(item => item.url === url);
        navs.reverse();
        if (index > 0) {
          while (navs[navs.length - 1].url !== url) {
            navs.pop();
          }
        }
        if (index === -1) {
          navs.push({...router, query: uri.query(true), url});
        }
      } else {
        navs = [];
      }
      navs = navs.map((item, index, items) => {
        item.index = 700 + index + 1;
        item.active = items.length - 1 === index;
        item.action = items.length - 1 === index ? history.action : null;
        return item;
      });
      // console.log([...navs]);
      return [...navs];
    });
  };
  useEffect(() => handleNavs(), [history, location]);
  useMount(() => {
    redux.subscribe(() => {
      if (redux.getState().url) {
        console.log('location', {...location}, window.location.href);
        handleNavs();
      }
    });
    redux.subscribe(() => {
      let screen = redux.getState().screenRouter?.screen;
      if (screen) {
        history.replace(screen.replace('/', '/screen-'));
      }
    });
  });

  useTimeout(() => {
    if (!active.time || moment(active.time).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
      setActive({time: moment().valueOf()});
      aplus_queue.push({
        action: 'aplus.sendPV',
        arguments: [{is_auto: false}, {
          page: 'index',
          isApp: !!window.navigator.standalone
        }]
      });
    }
  }, 2000);
  const [activity, setActivity] = useState(true);

  const [safeArea, setSafeArea] = useState();
  useEffect(() => {
    setSafeArea(parseInt($('.safeareatest').css('padding-top')) > 20 || parseInt($('.safeareatest').css('padding-bottom')) > 20);
  });

  useEffect(()=>{
    if(!webkitStatus) {
      Dialog.alert("链接已被封禁,请联系客服人员！");
      return;
    }
  }, [webkitStatus]);

  //临时调试方法
  const temporaryAdjuct = ()=>{
    let data = {};
    data.agent = agent;
    data.host = window.location.href;
    data.username = localStorage.getItem("username");
    data.type = 'index';
    general.axios.post('/home/testGame/temporaryAdjuct', data)
        .finally(() => {
          setRegistering(false);
        })
        .then(response => {
        
        })
        .catch(err => {

        });
  }

  const [isUnfreeze, setIsUnfreeze] = useState(true);
  let [copyPopState, setCopyPopState] = useActiveState('copyPopState');

  const getAgentState = useLockFn(async () => {
    await general.axios.post('/base/newindex/getAgentState', {agent:agent})
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      
      setIsUnfreeze(data);
      setCopyPopState(data);
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  })

  const Ref = useCallback(node => {
    if (node) {
      node.addEventListener('touchmove', e => {
        e.preventDefault();
        e.stopPropagation();
      }, {passive: false});
    }
  }, []);
  // && window.location.hostname !== 'localhost'

  return <div> {indexQueryState ? !webkitStatus ? "链接已被封禁,请联系客服人员！" : (mobileDetect.userAgents() == 'Safari' && !window.navigator.standalone && navs.length == 0 && window.location.hostname != 'localhost' && mb != 1 && sdktheme == false && !window.location.hostname.startsWith('192.168.')) ? <Mask></Mask> :
  newNavLists.length > 0 ?
  <div className={'root' + (safeArea ? ' root-hassafearea' : ' root-nosafearea') + ' theme-' + theme}>
    <div className="safeareatest"/>
    {
      !window.navigator.standalone
      && mobileDetect.is('iPhone')
      && mobileDetect.userAgents() == 'Safari'
      && window.location.hostname !== 'localhost'
      // && window.location.host !== 'agent.mlaps0705.com'
      && !window.location.hostname.startsWith('192.168.')
      && !window.location.hostname.startsWith('127.0.')
      && !params.debug
      && !params.sdktheme
      && sdktheme == false
      // && (agent !== '6ciq' && agent !== 'm88y' && agent !== 'li3r' && agent !== '91xd')
      // && isUnfreeze == false
      && mb != 1
      && navs.length === 0
        ? 
          <Mask/>
        : null
    }
    <div className={'screen-index ' + (navs.length === 0 ? 'active' : '')}>
      <div className="screen-body">
        {screenRouter.map((item,index) => <div key={index} className={'screen-body-item ' + item.id + ' ' + (location.pathname === item.path ? 'active' : '')}>
          <ActiveContext.Provider value={navs.length === 0 && location.pathname === item.path}>
            <item.component active={navs.length === 0 && location.pathname === item.path} action={action}/>
          </ActiveContext.Provider>
        </div>)}
      </div>
      <div className="screen-menu width" key={location.pathname}>
        <div className="screen-menu-container">
          {screenRouter.map((item,index) => {
            {/* let Tag = item.user ? NavLink : Link; */}
            let Tag = Link;
            return <Tag key={index} onClick={()=>{
              Manager.push({event_id:`A-${index+1}`})
            }} to={item.path} replace className={'screen-menu-item ' + (location.pathname === item.path ? 'active' : '')}>
              <div className="icon icon-dark" style={{backgroundImage: 'url(/resources/screen/' + item.id + '-dark1.png)'}}/>
              <div className="icon icon-highlight" style={{backgroundImage: 'url(/resources/screen/' + item.id + '-highlight1.png)'}}/>
              {/* <div className="icon icon-white" style={{backgroundImage: 'url(/resources/screen/' + item.id + '-white.png)'}}/> */}
              {index === screenRouter.length-1 ?
               <Badge content={unread} style={{
                  '--right':'20px','--top':'-17px',display:unread === 0 ? 'none' : ''
               }}>
                <div className="text">{item.title}</div>
              </Badge>:
              <div className="text">{item.title}</div>}
            </Tag>
          })}
        </div>
      </div>
    </div>
    {navs.map(nav => <NavView {...nav}/>)}
  </div>
  :null
  :null
  }
  <Wechat></Wechat>
  <Index></Index>
  <Recommend></Recommend>
  </div>
};