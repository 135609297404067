import {Link, useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import general from "../../../general";
import $ from "jquery";
import {useLockFn, useScroll, useMount} from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import useActiveState from '../../../lib/useActiveState';
import Subtitle from "../../components/Subtitle";
import { RightOutlined } from "@ant-design/icons";
import { NavLink } from "../../../lib/Navigation";
import { Input } from "antd-mobile";
import Manager from '../../components/Manager'

export default ({active, type, tag}) => {
  const {aplus_queue} = window;
  let history = useHistory();
  const [user] = useActiveState('user');
  const [page, setPage] = useState(1);
  // const [sort, setSort] = useState(2);
  const [sort, setSort] = useState('def');
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [obsolete, setObsolete] = useState();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [randomColorInfo] = useActiveState('randomColorInfo');
  const [infoTextColor, setInfoTextColor] = useState();
  const [init, setInit] = useState(false);
  const [playingGames, setPlayingGames] = useState([]);
  const [inputFlag, setInputFlag] = useState(false);
  const [search_info, setSearch_info] = useState('');

  console.log(history);
  console.log(active);

  useEffect(()=>{
    playings()
  },[localStorage.getItem('username')])

  const playings = () => {
    if (tag != 1010){
      return
    }
    let tmp = {
      pagination: { page: 1 },
    };
    general.axios.post('/base/wechatMiniGame/getPlayedMiniGame', tmp)
      .then(response => {
        let result = response.data.data;
        if (result.game_list.length > 10){
          setPlayingGames(result.game_list.slice(0, 10));
        }else {
          setPlayingGames(result.game_list);
        }
      })
  };

  const load = useLockFn(async (parms = {}) => {
    if (parms.search_info?.length == 0){
      playings()
    }
    if(loading) {
      return;
    }
    if ((parms.page == 1 && parms.search_info?.length > 0) || (parms.page == 1 && parms.search_info == '')){
      
    }else {
      Dialog.loading();
    }
    setLoading(true);

    let tmp = {
      search_info:parms.search_info || '',
      tab: sort,
      game_species_type: '',
      game_classify_type: tag,
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    const url = tag == '1010' ? '/base/wechatMiniGame/getMiniGameList' : '/base/game/getTypeGames'
    general.axios.post(url, tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        setObsolete(false);
        setLoaded(true);
        parms.page && setPage(parms.page);
        let {game_list: list, paginated} = data;
        list.forEach(function(item){
          item.infoColor = randomColorInfo[parseInt(Math.random() * 4)]
        }) 
        setGames(games => parms.page === 1 || !parms.page ? list : [...games, ...list]);
        setMore(paginated.more && list.length > 0);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  // console.log(games);
  useMount(() => load());
  useEffect(() => {
    // console.log(type)
    // console.log("123");
    // console.log(tag);
    // console.log("456");
    // console.log(sort);
    // console.log(active);
    if (active) {
      load({page: 1});
    } else {
      setObsolete(true);
    }
  }, [type, tag, sort]);


  // useEffect(() => {
  //   if (active && obsolete) {
  //     load({page: 1});
  //   }
  // }, [active, obsolete]);

  const scroll = useScroll();
  useEffect(() => {
    if (active) {
      if (scroll.top < -80) {
        load({page: 1 ,search_info:search_info});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, active, search_info]);

  // console.log(randomColorInfo[parseInt(Math.random() * randomColorInfo.length, 10)]);

  //分类游戏 点击埋点
  const clickTheGameOfCategories = (item) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickTheGameOfCategories', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), typename: tag, gamename:item}]
    });
  }

  const goPlaying = (game_id)=>{
    playings()
    general.axios.post('/base/wechatMiniGame/goPlaying', {game_id})
  }

  const handleCompositionStart = ()=>{
    setInputFlag(true)
  }

  const handleCompositionEnd = (e)=>{
    setInputFlag(false)
    setSearch_info(e.target.value)
    load({page:1,search_info:e.target.value})
  }

  return <div className={'game-type' + (!tag ? ' notag' : '')}>
    <div className="option-head" style={{
      position:(tag == 1010 ? 'relative' : 'fixed'),
      top: (tag == 1010? '0' : 'calc(env(safe-area-inset-top) + 40px)'),
      width:(tag == 1010? '100%' : '80%'),
      zIndex: (tag == 1010? '1' : '100') 
    }}>
      {tag != 1010 && <div className="head">
        {[
          {id: 2, text: '默认排序',tab:'def'},
          {id: 3, text: '最新上架',tab:'new'},
          {id: 1, text: '热门游戏',tab:'hot'}
        ].map(item => <div
          className={'item ' + (sort == item.tab ? 'active' : '')}
          onClick={() => setSort(item.tab)}
        >
          {item.text}
        </div>)}
      </div>}
      {tag == 1010 && 
      <div>
        <div style={{
          backgroundColor:'#F0F0F0',
          borderRadius:'18px',
          display:'flex',
          justifyContent:'flex-start',
          alignItems:'center',
          margin:'10px 8px 0',
          padding:'6px 15px'
        }}>
          <img src="resources/community/search.png" height={13} width={13} alt=""></img>
          <Input style={{
            '--placeholder-color':'#999',
            '--font-size':'13px',
            '--color':'#282828',
            paddingLeft:'5px'
          }} type="search" placeholder="搜索小游戏" 
          onCompositionStart={handleCompositionStart} 
          onCompositionEnd={handleCompositionEnd}
          onChange={value=>{
            if (inputFlag){
              return
            }
            setSearch_info(value)
            load({page:1,search_info:value})
          }}
          />
        </div>
        <NavLink user={true} to="/playing" className="head" 
          style={{
            display:'flex',
            padding:'0 10px',
            justifyContent:'space-between',
            color:'#282828',
            fontSize:'16px',
            fontWeight:'500'
          }}>
          <div>最近在玩</div>
          <RightOutlined style={{fontSize:'10px',color:'#999'}}></RightOutlined>
        </NavLink>
        <div className="playing-games" style={{
          height:'100px',
          backgroundColor:'white',
          borderRadius:'13px',
          margin:'0 10px',
          display:'flex',
          paddingRight:'10px',
          justifyContent:playingGames.length == 0 ? 'center' : 'flex-start',
          alignItems:'center',
          overflow:'scroll'
        }}>
          {playingGames.map((game,index)=>{
            return (
              <div style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                paddingLeft:'10px',
                width:'25%',
                flexShrink:'0'
              }} onClick={()=>window.open(game.single_promot_url)}>
                <Image style={{borderRadius:'13px'}} width={60} height={60} src={game.game_image.thumb} />
                <div style={{
                  fontSize:'12px',
                  color:'#282828',
                  marginTop:'5px',
                  overflow:'hidden',
                  textOverflow:'ellipsis',
                  whiteSpace:'nowrap',
                  width:'100%',
                  textAlign:'center',
                  overflow:'scroll'
                }} className="info">{game.game_name}</div>
              </div>
            )
          })}
          {playingGames.length == 0 && <div style={{
            display: 'flex',
            alignItems:'center',
            justifyContent:'center',
          }}>
            <img width={20} src="resources/game/no-playing.png" alt="" />
            <div style={{color:'#AAAAAA',fontSize:'12px',paddingLeft:'5px'}}>您还未玩任何小游戏</div>
          </div>}
        </div>
        <div style={{fontSize:'16px',color:'#282828',fontWeight:'500',padding:'10px 10px 0'}}>爆款好游</div>
      </div>}
    </div>
    <div className="body" style={{marginTop:(tag == 1010 ? '190px' : '45px')}}>
      {games.map((game,i) => <NavLink user={tag == 1010 ? true : false} to={'/game?id=' + game.game_id + ('&type='+type)} className="item" onClick={e=>{
        if (tag == 1010){
          Manager.push({event_id:'E-1',data:{gameid:game.game_id}})
          window.open(game.single_promot_url)
          e.preventDefault()
          e.stopPropagation()
        }else{
          clickTheGameOfCategories(game.game_name)
        }
        }}>
        {(()=>{
          if(sort == 'def' || sort == 'hot') {
            if(i == 0 || i==1 || i == 2) {
              return <div className="index">
                <img src={"/resources/index/new-" + (i + 1) + ".png"} />
              </div>
            }
          }
        })()}
        <div className="icon">
          <Image src={game.game_image.thumb}/>
          {/* <Image src={game.game_image.thumb}/> */}
          {/* {game.discount < 1 ? <div className="discount">{game.discount * 10}折</div> : null} */}
          {
            game.top_lable != '' ?
            <div className="iconlable jx iconlable-font" style={{backgroundImage: 'linear-gradient('+game?.lableColor?.t+', '+game?.lableColor?.b+')'}}>
              {game.top_lable}
            </div>
            : null
          }
        </div>
        <div className="text">
          <div className={'name ' + (game.discount < 1 ? '' : 'complate')}>
            {/* {game.top_lable != '' ? <span>{game.top_lable }</span> : null }{game.game_name} */}
            <div className={" " + (game.nameRemark ? game.nameRemark.length > 10 ? " name-new-length" : game.discount < 1 ? "  discount-name" :  " name-new" : "")}>
              {/* {game.top_lable != '' ? <span className="span">{game.top_lable }</span> : null } */}
              {game.game_name}
            </div>
          </div>
          <div className="info1">
            {/* {
              game.nameRemark ?
              <span className="nameRemark">
                {game?.nameRemark}&nbsp;
              </span>
              : null
            }
            {
              game.nameRemark ?
              <>{game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")}</>
              :
              <>{game.game_classify_type.trim()}</>
            } */}
            {/* {
              game.game_classify_type.split(' ').map(item => item?<span >
                {item}
              </span>:' ')
            } */}

            {/* {!game.nameRemark && game.game_species_type != 3 ? <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</> : null} */}
            {tag == 1010 ? 
              <span>
              {game.game_classify_type.split(' ').map(item =><span style={{
                backgroundColor:'#F2F2F2',
                padding:'1px 3px',
                marginRight:'3px',
                textAlign:'center',
                borderRadius:'2px',
                fontSize:'11px',
                color:'#999'
              }}>
                  {item}
                </span>)
              }
              </span> :
              <span>{game.game_classify_type.trim()}</span>
            }
            <Subtitle>{game.nameRemark}</Subtitle>
          </div>
          {/* <div className="info2" style={{color: game.infoColor}}>
            {game.game_desc ? game.game_desc.split('+').map(item => <div
              className="tag">{item}</div>) : game.introduction}
          </div> */}
          <div className="info3">
            {
              game.bottom_lable ? 
              <>
              {Object.keys(game.bottom_lable).length > 0 ? 
              <div className="bottom-lable">
                <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
              </div> : null }
              </>
              : null
            }
            {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
            {game.is_support_accele ? <div className="speed">可加速</div> : null}
            {game.is_easy_play ? <div className="speed">省心玩</div> : null}
            {game?.bottom_lable && Object.keys(game?.bottom_lable)?.length == 0 && !game?.is_support_accele && !game?.is_easy_play && game?.introduction && game?.exclusive_game_describe?.length == 0 ? <span style={{color:'#888'}}>{game?.introduction}</span> : null}          
            {tag == 1010 && <span style={{color:'#888'}}>{game.introduction}</span>}
            </div>
          {game.discount < 1 ? <div className="discount" ><span>{(game.discount * 10).toFixed(1)}</span>折</div> : null}
          {tag == 1010 && 
          <div onClick={e=>{
            Manager.push({event_id:'E-1',data:{gameid:game.game_id}})
            e.preventDefault();
            e.stopPropagation();
            if(!general.isLogin()) {
                PubSub.publish('alert-login',true)
                return;
              }
            window.open(game.single_promot_url)
            goPlaying(game.game_id)
          }} 
          style={{
            padding:'3.5px 10px',
            borderRadius:'14px',
            backgroundColor:'#E0EDFD',
            position:'absolute',
            right:'0px',
            top:'10px'
            }}>
            <img height={10} src="resources/game/play.png" alt="" />
            <span style={{color:'#0567DF',fontSize:'11px',paddingLeft:'4px',fontWeight:'500'}}>去玩</span>
          </div>}
        </div>
      </NavLink>)}
      {games.length > 6 && !more ? <div className="view-end">更多游戏 请搜索查找</div> : null}
      {games.length === 0 && loaded ? <div className="recovery-empty"><img src="/resources/game/screen_game_empty.png" alt=""/>暂无此类游戏</div> : null}
    </div>
  </div>;
};