import {useEffect, useState, useRef} from "react";
import {useLockFn, useMount} from "ahooks";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import {useHistory, useLocation} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import URI from "urijs";
import Image from "../../lib/Image";
import {NavLink} from "../../lib/Navigation";
import MobileDetect from "mobile-detect";
import moment from "moment";
import useUrlState from "@ahooksjs/use-url-state";
import {Link} from "react-router-dom";

const vipequity = [
  {
    id: 'month',
    title: '会员月卡',
    intro: '开卡即回本，最高可省20元',
    old_amount: '30',
    amount: '9.9'
  },
  {
    id: 'season',
    title: '会员季卡',
    intro: '开卡即回本，最高可省60元',
    old_amount: '90',
    amount: '19.9',
    hot_title: '推荐'
  },
  {
    id: 'year',
    title: '会员年卡',
    intro: '开卡即回本，最高可省290元',
    old_amount: '360',
    amount: '69.9',
    hot_title: '超划算'
  }
];

export default props => {
  const {aplus_queue} = window;
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState();
  const [isCheckAdult, setIsCheckAdult] = useState(false); //未成年温馨提示状态
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [authData, setAuthData] = useState({}); //实名认证参数值
  const [user, setUser] = useActiveState('user');
  const [config] = useActiveState('config');
  const [agent] = useActiveState('agent');
  const [card, setCard] = useState({});
  const [datas, setDatas] = useState();
  const [vip, setVip] = useState({});
  const mobileDetect = new MobileDetect(navigator.userAgent);
  const [textTips, setTextTips] = useState([]);
  const [init, setInit] = useState(true);

  const [vipInfo, setVipInfo] = useState();
  const [levelItems, setLevelItems] = useState();
  const [regularState, setRegularState] = useState(false);//老会员支付提醒弹框
  const [vipPopState, setVipPopState] = useState(false); //会员弹框
  const [equityType, SetEquityType] = useState(''); //会员权益类型
  const [centerItems, setCenterItems] = useState(); //弹框选择信息
  const [packItens, setPackItems] = useState(); //会员礼包列表
  const [voucherItems, setVoucherItems] = useState(); //会员代金券列表
  const [firstClickTextState, setFirstClickTextState] = useState(false);
  const [gameAlert,setGameAlert] = useState(false)
  const [notApplicableList,setNotApplicableList] = useState([])
  const searchRef = useRef()

  useEffect(() => {
    props.setOperate(<Link to="/save/descript" >会员说明</Link>);
    getNotApplicableList()
  }, []);

  const getNotApplicableList = ()=>{
    const search_info = searchRef.current?.value
    general.axios.post('/getNotApplicableList',{tab:'vip',search_info:search_info,pagination:{page:'1',count:'99999'}})
    .finally(() => {
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      setNotApplicableList(data.list);
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  useEffect(()=>{
    if (alert){
      getNotApplicableList()
    }
  },[gameAlert])

  const load = () => {
    general.axios.post('/vipV2/index?noaccess=1')
    .finally(() => {
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }

      setVipInfo(data.vipInfo);
      setLevelItems(data.vipInfo.levelItems);
      setDatas(data);
      setData(data.vipInfo.levelItems.find(item => item.isChecked));
      // console.log(data);

      if(data.explain) {
        let tip = data.explain.split('\r\n');
        setTextTips(tip);
      }
      setInit(true);
      // return;
      
      // setCard(data.money_saving_card);
      // setDatas(data.money_saving_card.items);
      // setData(data.money_saving_card.items.find(item => item.active));
      // setVip(data.vip);
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  const pachs = () => {
    general.axios.post('/vipV2/getPackItems?noaccess=1')
    .finally(() => {
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }

      setPackItems(data.items);
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  const vouchers = () => {
    general.axios.post('/vipV2/getVoucherItems?noaccess=1')
    .finally(() => {
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }

      setVoucherItems(data.items);
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  useEffect(()=>{
    load();
    pachs();
    vouchers();
  }, [user]);

  const urlLogin = () => {
    if(general.isLogin()) {
      return;
    }

    if (params.userid && params.username && params.token) {
      localStorage.setItem('user_id', params.userid);
      localStorage.setItem('username', params.username);
      localStorage.setItem('token', params.token);

      load_load();
    }
  }
  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })
  useMount(urlLogin);

  const [pay, setPay] = useState();
  const [payUrl, setPayUrl] = useState();
  useEffect(() => {
    if (pay) {
      let url = URI(vipInfo.vip2PayUrl)
          .addQuery('username', localStorage.getItem('username'))
          .addQuery('token', localStorage.getItem('token'))
          .addQuery('grade_id', pay.grade_id)
          .addQuery('from', 'react');

      setPayUrl(url);

      setTimeout(()=>{
        setPay(null);
      }, 8000)
    } else {
      setPayUrl(null);
    }
  }, [pay]);

  //实名认证
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  })

  const cardPay = useLockFn(()=>{
    if(!general.isLogin()) {
      PubSub.publish('alert-login',true)
      return;
    }

    if(user.isCheckAuth && !user.isRealNameAuth) {
      setIsCheckAuth(true);
      return;
    }
    if(user.isCheckAdult && !user.isAdult) {
      setIsCheckAdult(true);
      return;
    }

    setPay({type: 'vip', grade_id: data?.levelId})
  })

  // console.log(centerItems);

  const confirm = () => {
    // 是不是会员 用level 判断
    // 新老会员 用version 判断
    
    // 不是会员 level =0 
    // 老会员 level 取值 1-5 
    // 新会员 level 取值 1-3

    //新会员
    if(vipInfo.version == 2) {
      if(vipInfo.level > data.levelId) {
        Dialog.info('不能购买低等级会员 ！');
      } else if(vipInfo.level <= data.levelId) {
        setRegularState(true);
      } else {
        cardPay();
      }
    } else if(vipInfo.version == 1) { //老会员
      setRegularState(true);
    } else {
      cardPay();
    }
  }

  {/* 代金券领取 */}
  const voucherRevice = () => {
    Dialog.loading();
    general.axios.post('/vipV2/receive')
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          setVipPopState(false);
          Dialog.info("领取成功");
          load();
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const [voucherPay, setVoucherPay] = useState(false);
  const [voucherPayUrl, setVoucherPayUrl] = useState();
  const voucherBuy = () => {
    if(vipInfo?.vip2BuyVoucherPayUrl) {
      let url = URI(vipInfo?.vip2BuyVoucherPayUrl)
        .addQuery('username', localStorage.getItem('username'))
        .addQuery('token', localStorage.getItem('token'))
        .addQuery('from', 'react');

      setVoucherPayUrl(url);
      setVoucherPay(true);

      setTimeout(()=>{
        setVoucherPay(false);
        setVipPopState(false);
        general.history.goBack();
      }, 8000)
    }
  }

  return init ? <div className="save-vip-container"><div className="save-vip-new">
    <div className="">
      <img src="/resources/save/new-vip-top.png" />
    </div>
    <div className="vip-index" style={{display:vipInfo ? '' : 'none'}}>
      <div className="vip-user-info" >
        <div className="bg-text">
          <div className="info-title">
            <div className="icon">
              <Image src={ !general.isLogin() ? '/resources/user/avatar.png' : user.avatar_status === '-1'? '/resources/user/avatar.png' :user.icon_link} alt="个人头像" className="avatar"/>
            </div>
            <div className="info">
              <div className="name">
                {localStorage.getItem('username') ? user?.nick_name : '未登录'}
              </div>
              <div className="desc">
                {/* {vipInfo.level == 0 ? '暂未购买' : <>会员有效期：<span className="end-date-time">{vipInfo?.lastDate}</span></>} */}
                {/* 会员有效期：<span className="end-date-time">{vipInfo?.lastDate}</span> */}
                {/* {card.is_buy ? card.end_date_time : '暂未购买'} */}
                {(() => {
                  // if(vipInfo?.version == 2) {
                  //   if(vipInfo?.level == 0) {
                  //     return '暂未购买';
                  //   } else {
                  //     return <>会员有效期：<span className="end-date-time">{vipInfo?.lastDate}</span></>
                  //   }
                  // } else {
                  //   return '暂未购买';
                  // }
                  if(vipInfo?.level == 0) {
                    return '会员有效期：暂未购买';
                  } else {
                    return <>会员有效期：<span className="end-date-time">{vipInfo?.lastDate}</span></>
                  }
                })()}
              </div>
            </div>
            {
              vipInfo?.version == 2 ?
              <>
              {(() => {
                if(vipInfo?.level == 1) {
                  return <div className='rank-level'><img src="/resources/user/monthly_card.png" /></div>
                } else if(vipInfo?.level == 2) {
                  return <div className='rank-level'><img src="/resources/user/season_card.png" /></div>
                } else if(vipInfo?.level == 3) {
                  return <div className='rank-level'><img src="/resources/user/year_card.png" /></div>
                }
              })()}
              </>
              : <>
              {vipInfo?.version == 1 && vipInfo?.level ?
              <div className="rank-level old-level"><Image src={"/resources/user/" + vipInfo.level + ".png"} /></div>
              : null}
              </>
            }
          </div>
        </div>
      </div>

      <div className="vip-type">
        {levelItems?.map(item => <div className={'item' + (item.levelId === data?.levelId ? ' active' : '')} onClick={() => setData(item)} style={{backgroundImage: 'url(/resources/save/new-vip-type-bg.png)'}}>
          {item.label ? <div className="good" style={{backgroundImage: 'url(/resources/save/vip-good.png)'}}>{item.label}</div> : null}
          <div className="info">
            <div className="title">
              <img src={item.titleImage} />
            </div>
            <div className="desc1">
              {item.remark}
            </div>
          </div>

          <div className="price">
            <p>
              <s>原价{item.originalPrice}元</s>
            </p>
            {item.nowPrice}元
          </div>
          <div className="choose"><img src="/resources/save/vip-choose.png" alt=""/></div>
        </div>)}
      </div>

      {data?.buttonTitle ? <div className="vip-btn" onClick={() => confirm()}>{data?.buttonTitle}</div> : null}
      <div className="vip-desc"> “会员卡”为虚拟产品，一经售出概不退换，请根据实际情况需要开通。</div>
      <div className="vip-game" onClick={()=>{
        setGameAlert(true)
      }}>查看会员卡不适用游戏
        <img src="/resources/community/more.png" alt="" width='7px' />
      </div>
      <div className="vip-title"><img src={data?.headerImage} alt=""/></div>
      {/* {
        card?.buttonDisplay == 1 ? <div className="card-btn-display">
          已开通
          <p>({card?.buttonTime})</p>
        </div>
        : <div className="vip-btn" onClick={cardPay}>{data?.submit_button_title || '立即开通'}</div>
      } */}
       
      {/* <div className="vip-tips">{data?.tips}</div> */}
    </div>
    
    <div className="vip-info" style={{backgroundImage: 'url(/resources/save/new-vip-info-bg.png)',display:data ? '' : 'none'}}>
      
      <div className="info-items">
        {/** 四边形 **/}
        <div className="quadrilateral" >
          {data?.centerItems?.map((item, index) => <div className="item" style={{backgroundImage: 'url(' + item.image + ')'}} 
            onClick={() => {
              if(item.index == 1) {
                SetEquityType('openCard');
              } else if(item.index == 2) {
                SetEquityType('monthly');
              } else if(item.index == 3) {
                SetEquityType('monthly');
              } else if(item.index == 4) {
                SetEquityType('buySaveCard');
              }
              setCenterItems(item);
              setVipPopState(true);
            }}>
            <div className="name" >{item.name1}</div>
            <div className="info1" >{item.name2}</div>
            <div className="info2" >{item.name3}</div>
          </div>)}
          {/* <div className="item" onClick={() => {SetEquityType('openCard');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip--info-six.png)'}}>
            <div className="name" >开卡赠礼</div>
            <div className="info1" >无门槛券*1张</div>
            <div className="info2" >开通后自动到账</div>
          </div>
          <div className="item" onClick={() => {SetEquityType('monthly');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip--info-six.png)'}}>
            <div className="name" >每月免费领</div>
            <div className="info1" >无门槛券*1张</div>
            <div className="info2" >每月1日免费领取</div>
          </div>
          <div className="item" onClick={() => {SetEquityType('weekly');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip--info-six.png)'}}>
            <div className="name" >每周8折购券</div>
            <div className="info1" >仅需20元</div>
          </div>
          <div className="item" onClick={() => {SetEquityType('buySaveCard');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip--info-six.png)'}}>
            <div className="name" >购买省钱卡福利</div>
            <div className="info1" >最高可多领5天</div>
          </div> */}
        </div>

        {/** 九边形 **/}
        <div className="enneagon">
          {data?.bottomItems?.map((item, index) => <div className="item" style={{backgroundImage: 'url(' + item.image + ')'}} 
            onClick={() => {
              if(item.index == 1) {
                SetEquityType('transfer_points');
              } else if(item.index == 2) {
                SetEquityType('transaction')
              } else if(item.index == 3) {
                SetEquityType('redemption');
              } else if(item.index == 4) {
                SetEquityType('cashcoupon');
              } else if(item.index == 5) {
                SetEquityType('giftbag')
              } else if(item.index == 6) {
                SetEquityType('signin');
              } else if(item.index == 7) {
                SetEquityType('vip_customer_service');
              } else if(item.index == 8) {
                SetEquityType('exclusive_activity');
              } else if(item.index == 9) {
                SetEquityType('gamerebate');
              }
              setCenterItems(item);
              setVipPopState(true);
            }}>
            <div className="name">{item.name1}</div>
            <div className="info">{item.name2}</div>
          </div>)}

          {/* <div className="item" onClick={() => {SetEquityType('transfer_points');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip-nine-one.png)'}}>
            <div className="name">转游点数加倍</div>
            <div className="info">1.2倍</div>
          </div>
          <div className="item" onClick={() => {SetEquityType('transaction');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip-nine-one.png)'}}>
            <div className="name">交易手续费</div>
            <div className="info">减免至4%</div>
          </div>
          <div className="item" onClick={() => {SetEquityType('redemption');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip-nine-one.png)'}}>
            <div className="name">赎回手续费</div>
            <div className="info">减免至2%</div>
          </div>
          <div className="item" onClick={() => {SetEquityType('cashcoupon');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip-nine-one.png)'}}>
            <div className="name">会员专属</div>
            <div className="info">游戏代金券</div>
          </div>
          <div className="item" onClick={() => {SetEquityType('giftbag');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip-nine-one.png)'}}>
            <div className="name">会员专属</div>
            <div className="info">游戏礼包</div>
          </div>
          <div className="item" onClick={() => {SetEquityType('signin');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip-nine-one.png)'}}>
            <div className="name">签到额外奖励</div>
            <div className="info">+1金币</div>
          </div>
          <div className="item" onClick={() => {SetEquityType('vip_customer_service');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip-nine-one.png)'}}>
            <div className="name">会员专属</div>
            <div className="info">VIP客服</div>
          </div>
          <div className="item" onClick={() => {SetEquityType('exclusive_activity');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip-nine-one.png)'}}>
            <div className="name">会员专属</div>
            <div className="info">专属活动</div>
          </div>
          <div className="item" onClick={() => {SetEquityType('gamerebate');setVipPopState(true)}} style={{backgroundImage: 'url(/resources/save/new-vip-nine-one.png)'}}>
            <div className="name">游戏返利</div>
            <div className="info">优先处理</div>
          </div> */}
        </div>
      </div>

      {/* {data?.firstItems?.map((item)=>
        // <div className="discount" 
        //    onClick={() => {
        //       Dialog.alert('使用规则', 
        //                   <div className="exchange-desc" dangerouslySetInnerHTML={{__html:data.firstClickText.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')}}>
        //                   </div>, () => {}, {btn: '我知道了',style:'know'})
        //     }}>
        <div className="info-discount" 
           onClick={() => {setFirstClickTextState(true)}}>
          <img src={item.image} alt="" />
          <div> 
              <div className="name" >{item.name1}</div>
              <div className="name-2" >{item.name2}</div>
              <div>
                <span className="name-2" >{item.name3}</span>
                <span className="desc" >（查看详情）</span>
              </div>
          </div>
        </div>
      )} */}

      <img src="resources/save/0.1-vip.png" alt="" />
      <img style={{marginTop:'10px'}} src="resources/save/0.05-vip.png" alt="" />

      {/* {
        card?.buttonDisplay == 1 ? <div className="card-btn-display">
          已开通
          <p>({card?.buttonTime})</p>
        </div>
        : <div className="vip-btn" onClick={cardPay}>{data?.submit_button_title || '立即开通'}</div>
      } */}
      {/* <div className="vip-btn" onClick={() => confirm()}>{data?.buttonTitle}</div> */}
    </div>

  </div>

  {/***实名认证弹框***/}
  {isCheckAuth?
  <div className="pay-check-auth">
      <div className="auth-index">
        <div className="close" onClick={() => setIsCheckAuth(false)}>
          <img src="/resources/user/close.png" />
        </div>
        <div className="title">
          <div className="title-img">
            <img src="/resources/user/auth-title.png" />
          </div>
          <div>
            <h3>实名认证</h3>
            <p>
            {config.authTopTips}
            </p>
          </div>
        </div>

        <div className="auth-user-realname">
          <div className="auth-user-input">
            <div className="name">真实姓名</div>
            <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
          </div>
          <div className="auth-user-input">
            <div className="name">身份证号</div>
            <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
          </div>
          <span>
          {config.authBottomTips}
          </span>
          <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
        </div>
      </div>
    </div> : null
    }

    {/** 未成年温馨提示弹框 **/}
    {
    isCheckAdult ? 
    <div className="pay-check-auth">
      <div className="auth-index adult-index">
        <div className="title">
          <div className="title-img">
            <img src="/resources/user/auth-title.png" />
          </div>
          <div>
            <h3>温馨提示</h3>
            <p>
            {config.adultTips}
            </p>
          </div>
        </div>
        <div className="auth-user-realname">
          <div className="auth-user-btn" onClick={()=>setIsCheckAdult(false)}>我知道了</div>
        </div>
      </div>
    </div>
    : null
    }


    {firstClickTextState ? 
    <div className="save-exchange-index">
        <div className="exchange-site">
          <div className="exchange-main">
            <div className="close" >
              <img src="/resources/user/close.png" onClick={() => setFirstClickTextState(false)}/>
            </div>
              <div className="exchange-title">
              使用规则
              </div>
              <div className="info">
                <div className="exchange-desc" dangerouslySetInnerHTML={{__html:data?.firstClickText.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')}}>
                          </div>
              </div>
              <div className="exchange-btn">
                <div className="item btn-about" onClick={() => setFirstClickTextState(false)} >
                  我知道了
                </div>
              </div>
          </div>
            
        </div>
    </div>
    : null}

    {/** 老用户支付判断 **/}
    {regularState ? 
    <div className="regular-user-pay-index">
      <div className="pay-site">
        <div className="pay-main">
          <div className="close" onClick={() => setRegularState(false)}>
            <img src="/resources/user/close.png" />
          </div>
          <div className="pay-title">
            温馨提示
          </div>
          <div className="info">
            您当前的会员等级是
            <span>
              {(() => {
                if(vipInfo.version == 2) {
                  if(vipInfo.level == 1) {
                    return '月卡'
                  } else if(vipInfo.level == 2) {
                    return '季卡'
                  } else if(vipInfo.level == 3) {
                    return '年卡'
                  }
                } else {
                  if(vipInfo.level == 1) {
                    return '子爵'
                  } else if(vipInfo.level == 2) {
                    return '伯爵';
                  } else if(vipInfo.level == 3) {
                    return '公爵';
                  } else if(vipInfo.level == 4) {
                    return '国王';
                  } else if(vipInfo.level == 5) {
                    return '皇帝';
                  }
                }
              })()}
              会员
            </span>，现购买会导致您<span>当前的会员等级、时长及相应权益被覆盖失效</span>，请确认是否继续购买。
          </div>
          <div className="pay-btn">
            <div className="item btn-about" onClick={() => setRegularState(false)}>
              我再想想
            </div>
            <div className="item btn-confirm" onClick={() => {setRegularState(false);cardPay()}}>
              继续购买
            </div>
          </div>
        </div>
      </div>
    </div>
    : null}

    {/** 会员弹框 **/}
    {vipPopState ? 
    <div className="new-vip-index-intro" >
      <div className="vip-site">
        <div className="new-vip-pop-main">
          <div className="intro-pop-section">
              <div className="vip-intro-title">
                <img src={"/resources/save/" + (vipInfo?.level == 0 || vipInfo?.version == 1 || vipInfo?.level != data?.levelId ? 'vip-interests-no' : 'vip-interests-yes') + ".png"} />
              </div>

              <div className="vip-intro-and-object">
                {/** 标题 **/}
                <div className="title">
                  {(() => {
                    if(equityType == 'openCard') {
                      //开卡
                      return <img src="/resources/save/open-card-give-gifts.png" />;
                    } else if(equityType == 'monthly') {
                      //每月
                      return <img src="/resources/save/monthly-title2.png" />;
                    } else if(equityType == 'weekly') {
                      //每周
                      return <img src="/resources/save/weekly-title.png" />;
                    } else if(equityType == 'buySaveCard') {
                      //买省钱卡
                      return <img src="/resources/save/buysavecard-title.png" />;
                    } else if(equityType == 'transfer_points') {
                      //转游点数
                      return <img src="/resources/save/transfer_points-title.png" />;
                    } else if(equityType == 'transaction') {
                      //交易
                      return <img src="/resources/save/transaction-title.png" />;
                    } else if(equityType == 'redemption') {
                      //赎回
                      return <img src="/resources/save/redemption-title.png" />;
                    } else if(equityType == 'signin') {
                      //签到
                      return <img src="/resources/save/signin-title.png" />;
                    } else if(equityType == 'giftbag') {
                      //礼包
                      return <img src="/resources/save/giftbag-title.png" />;
                    } else if(equityType == 'cashcoupon') {
                      //优惠券
                      return <img src="/resources/save/cashcoupon-title.png" />;
                    } else if(equityType == 'exclusive_activity') {
                      //专属活动
                      return <img src="/resources/save/exclusive_activity-title.png" />;
                    } else if(equityType == 'vip_customer_service') {
                      //vip客服
                      return <img src="/resources/save/vip_customer_service-title.png" />;
                    } else if(equityType == 'gamerebate') {
                      //游戏返利
                      return <img src="/resources/save/gamerebate-title.png" />;
                    } else {
                      return <img src="/resources/save/open-card-give-gifts.png" />;
                    }
                  })()}
                </div>
                <div className="tips">
                  {(() => {
                    //未购买前开
                    if(equityType == 'openCard') {
                      let text;
                      if(vipInfo.level == 0 || vipInfo.version == 1 || vipInfo.level != data?.levelId) {
                        text = '开通即赠送无门槛代金券，自动到账！代金券仅限0.1折/BT游戏可用，会员开卡所赠送的代金券有效期为7天，可前往我的-代金券页面查看详情';
                        // text = '开通即赠送无门槛代金券，自动到账！代金券仅限BT游戏可用';
                      }
                      return text;
                    } else if(equityType == 'monthly') {
                      let text;
                      if(vipInfo.level == 0 || vipInfo.version == 1 || vipInfo.level != data?.levelId) {
                        text = '会员每月1日自动赠送一张无门槛代金券，会员等级越高，代金券金额越大，代金券仅限0.1折/0.05 折/BT游戏可用';
                        // text = '会员每月1日可免费领取无门槛代金券，会员等级越高，代金券金额越大，代金券仅限BT游戏可用';
                      }
                      return text;
                    } else if(equityType == 'weekly') {
                      //购买前
                      // return '会员可享受每周半价购券的特权，会员等级越高，购券折扣越大';
                      //购买后
                      // return '8折购无门槛券';
                      let text;
                      if(vipInfo.level == 1) {
                        text = '8折购无门槛券'
                      } else if(vipInfo.level == 2) {
                        text = '7折购无门槛券'
                      } else if(vipInfo.level == 3) {
                        text = '5折购无门槛券'
                      } else {
                        text = '会员可享受每周折扣购券的特权，会员等级越高，购券折扣越大，代金券仅限0.1折/BT游戏可用';
                        // text = '会员可享受每周折扣购券的特权，会员等级越高，购券折扣越大，代金券仅限BT游戏可用';
                      }
                      if(vipInfo.version == 1 || vipInfo.level != data?.levelId) {
                        text = '会员可享受每周折扣购券的特权，会员等级越高，购券折扣越大，代金券仅限0.1折/BT游戏可用';
                        // text = '会员可享受每周折扣购券的特权，会员等级越高，购券折扣越大，代金券仅限BT游戏可用';
                      }
                      return text;
                    } else if(equityType == 'buySaveCard') {
                      return '会员用户购买省钱卡可额外享受省钱卡有效期延长福利，有效期期间每天可领取50金币，最高可多领500金币，相当于白捡50元！';
                    } else if(equityType == 'transfer_points') {
                      return <><p>会员用户可享受转游点数加倍福利！</p>
                        <p>普通用户根据充值金额1:1获得转游点数，会员用户转游点数加倍！</p>
                      </>;
                    } else if(equityType == 'transaction') {
                      return '普通用户小号交易手续费为交易金额的5%，会员用户可享受手续费减免！';
                    } else if(equityType == 'redemption') {
                      return '普通用户回收后的小号赎回需额外支付该小号充值金额的3%作为赎回手续费，会员用户可享受手续费减免';
                    } else if(equityType == 'signin') {
                      return '会员用户签到可获得额外的金币奖励';
                    } else if(equityType == 'exclusive_activity') {
                      return '平台会不定期推出会员专属活动，会员可独享更多福利优惠（具体视活动规则而定），敬请期待。';
                    } else if(equityType == 'vip_customer_service') {
                      return '咨询客服快人一步！会员专享绿色客服通道，快速响应。全程为您贴心跟进事情处理进度，全方位为您服务！';
                    } else if(equityType == 'gamerebate') {
                      return '会员用户享受返利优先处理的特权，提交返利急速处理，告别长时等待！';
                    } else {
                      return ;
                    }
                  })()}
                </div>

                {/** 内容 **/}
                {(() => {
                  if(equityType == 'openCard') {
                    return <div className="open-card-give-gift">
                      {(()=>{
                        if(vipInfo?.level == 0 || vipInfo?.version == 1 || vipInfo?.level != data?.levelId) {
                          {/** 购买成功前 **/}
                          return <div className="type-give-matter">
                            <p>会员月卡：赠送6元无门槛券 *1张</p>
                            <p>会员季卡：赠送6元无门槛券 *2张</p>
                            <p>会员年卡：赠送6元无门槛券 *8张</p>
                          </div>
                        } else {
                          {/** 购买成功后 **/}
                          return <div className="open-card-win">
                            <div className="icon">
                              {(() => {
                                let img;
                                if(vipInfo.level == 1) {
                                  img = "/resources/save/open-card-ticket-one.png";
                                } else if(vipInfo.level == 2) {
                                  img = "/resources/save/open-card-ticket-two.png";
                                } else if(vipInfo.level == 3) {
                                  img = "/resources/save/open-card-ticket-three.png";
                                }

                                return <img src={img} />
                              })()}
                              {/* <img src="/resources/save/open-card-ticket-one.png" /> */}
                            </div>
                            <div className="text">
                              {(() => {
                                let text;
                                if(vipInfo.level == 1) {
                                  text = '6元无门槛券*1张  有效期：7天'
                                } else if(vipInfo.level == 2) {
                                  text = '6元无门槛券*2张  有效期：7天'
                                } else if(vipInfo.level == 3) {
                                  text = '6元无门槛券*8张  有效期：7天'
                                }

                                return text
                              })()}
                            </div>
                            <div className="operate">已到账</div>
                            <div className="text2">可前往我的-代金券页面查看详情</div>
                          </div>
                        }
                      })()}
                    </div>
                  } else if(equityType == 'monthly') {
                    return <div className="monthly-module">
                      {(() => {
                        if(vipInfo?.level == 0 || vipInfo?.version == 1 || vipInfo?.level != data?.levelId) {
                          return <>
                            {/** 购买前 **/}
                            <div className="icon"><img src="/resources/save/monthly-overall.png" /></div>
                            <div className="type-receive">
                              <div>月卡每月可赠送</div>
                              <div>季卡每月可赠送</div>
                              <div>年卡每月可赠送</div>
                            </div>
                            <div className="type-give-matter">
                              <p>月卡会员：每月赠送 3元无门槛券*1张</p>
                              <p>季卡会员：每月赠送 6元无门槛券*1张</p>
                              <p>年卡会员：每月赠送 10元无门槛券*1张</p>
                            </div>
                            <div className="text-des1">
                              会员每月1日自动赠送一次代金券，次数在下个月1日重置，错过不再补发，有效期7天，赠送后可前往我的-代金券页面查看详情。
                            </div>
                          </>
                        } else {
                          return <>
                            {/** 购买后 **/}
                            <div className="icon">
                              {(() => {
                                if(vipInfo.level == 1) {
                                  return <img src="/resources/save/monthly-ticket-one.png" />
                                } else if(vipInfo.level == 2) {
                                  return <img src="/resources/save/monthly-ticket-two.png" />
                                } else if(vipInfo.level == 3) {
                                  return <img src="/resources/save/monthly-ticket-three.png" />
                                }
                              })()}
                            </div>
                            <div className="text">有效期：7天</div>
                            {/* 新增字段 receiveVoucherStatus 位置在 canReceiveVoucher 同级 
                            字段值说明: [1:可以领取, 2:已领取, 3: 已失效 ]

                            判断规则: 先判断是不是会员, 及新会员 , 如果不是, 没有按钮, 否则根据上面字段, 进行判断显示 */}
                            {/* receiveVoucherStatus */}
                            {centerItems?.receiveVoucherStatus == 1 ?
                            <div className="operate" onClick={() => voucherRevice()} style={{backgroundImage: 'url(/resources/save/new-vip-operate-bg.png)'}}>立即领取</div>
                            :
                            <div className="already-op">{centerItems?.receiveVoucherStatus == 2 ? '已领取' : '未到领取日期'}</div>
                            }
                          </>
                        }
                      })()}

                      {/* {
                        centerItems?.canReceiveVoucher ? <>
                        <div className="icon"><img src="/resources/save/monthly-ticket-one.png" /></div>
                        <div className="text">有效期：7天</div>
                        <div className="operate" style={{backgroundImage: 'url(/resources/save/new-vip-operate-bg.png)'}}>立即领取</div>
                        </>
                        :
                        <>
                        <div className="icon"><img src="/resources/save/monthly-overall.png" /></div>
                        <div className="type-receive">
                          <div>月卡每月可领</div>
                          <div>季卡每月可领</div>
                          <div>年卡每月可领</div>
                        </div>
                        <div className="type-give-matter">
                          <p>月卡会员：每月可领取 3元无门槛券*1张</p>
                          <p>季卡会员：每月可领取 6元无门槛券*1张</p>
                          <p>年卡会员：每月可领取 10元无门槛券*1张</p>
                        </div>
                        <div className="text-des1">
                          代金券需前往该页面领取，每月可领取一次，次数在每月1日重置，代金券有效期7天，领取后可前往我的-代金券页面查看详情。
                        </div>
                        </>
                      } */}
                      
                    </div>
                  } else if(equityType == 'weekly') {
                    return <div className="weekly-module">
                      {(() => {
                        if(vipInfo?.level == 0 || vipInfo?.version == 1 || vipInfo?.level != data?.levelId) {
                          return <>
                            {/** 购买前 **/}
                            <div className="icon"><img src="/resources/save/weekly-overall.png" /></div>
                            <div className="type-receive">
                              <div>5元无门槛券*1张</div>
                              <div>20元无门槛券*1张</div>
                            </div>
                            <div className="type-give-matter">
                              <p>会员月卡：8折购  20元  <s>25元</s></p>
                              <p>会员季卡：7折购  17元  <s>25元</s></p>
                              <p>会员年卡：5折购  13元  <s>25元</s></p>
                            </div>
                            <div className="text-des1">
                              代金券每次购买会消耗当周的购买次数，每周仅能购买一次，次数在每周一会重置，代金券有效期7天，购买后可前往我的-代金券页面查看详情。
                            </div>
                          </>
                        } else {
                          return <>
                            {/** 购买后 **/}
                            <div className="icon"><img src="/resources/save/weekly-overall.png" /></div>
                            <div className="text">
                              {(() => {
                                if(vipInfo.level == 1) {
                                  return <>有效期：7天   20元  <s>25元</s></>
                                } else if(vipInfo.level == 2) {
                                  return <>有效期：7天   17元  <s>25元</s></>
                                } else if(vipInfo.level == 3) {
                                  return <>有效期：7天   13元  <s>25元</s></>
                                }
                              })()}
                            </div>
                            {centerItems?.canBuyVoucher ? 
                              <div className="operate" onClick={() => voucherBuy()} style={{backgroundImage: 'url(/resources/save/new-vip-operate-bg.png)'}}>立即购买</div>
                            : <div className="already-op">已购买</div>}
                            {/* <div className="already-op">已领取</div> */}
                          </>
                        }
                      })()}
                    </div>
                  } else if(equityType == 'buySaveCard') {
                    return <div className="buySaveCard-module">
                      <div className="icon"><img src="/resources/save/buysavecard-table.png" /></div>
                      {vipInfo.level != 0 && vipInfo.version == 2 && vipInfo.level == data?.levelId ? <>
                        {/** 购买后显示 **/}
                        <NavLink to="/save/newcard" onClick={() => setVipPopState(false)} className="operate" style={{backgroundImage: 'url(/resources/save/new-vip-operate-bg.png)'}}>立即购买</NavLink>
                        </>
                        : null
                      }
                    </div>
                  } else if(equityType == 'transfer_points') {
                    //转游点数
                    return <div className="transfer_points-module">
                      <div className="type-give-matter">
                        <p>月卡会员：转游点数可按充值金额1.2倍兑换</p>
                        <p>季卡会员：转游点数可按充值金额1.5倍兑换</p>
                        <p>年卡会员：转游点数可按充值金额2倍兑换</p>
                      </div>
                      <div className="text-des1">
                        转游点数可兑换游戏稀有礼包和大额代金券！
                      </div>
                    </div>
                  } else if(equityType == 'transaction') {
                    //交易
                    return <div className="transaction-module">
                      <div className="type-give-matter">
                        <p>月卡会员：小号交易手续费减免至4%</p>
                        <p>季卡会员：小号交易手续费减免至3%</p>
                        <p>年卡会员：小号交易手续费减免至2%</p>
                      </div>
                    </div>
                  } else if(equityType == 'redemption') {
                    //赎回
                    return <div className="redemption-module">
                      <div className="type-give-matter">
                        <p>月卡会员：小号赎回手续费减免至2%</p>
                        <p>季卡会员：小号赎回手续费减免至1%</p>
                        <p>年卡会员：小号赎回免手续费</p>
                      </div>
                    </div>
                  } else if(equityType == 'signin') {
                    //签到
                    return <div className="signin-module">
                      <div className="caption">签到可获得的额外金币</div>
                      <div className="icon"><img src="/resources/save/signin-table.png" /></div>
                      {/** 购买后显示 **/}

                      {vipInfo.level != 0 && vipInfo.version == 2 && vipInfo.level == data?.levelId ? 
                      <NavLink to="/task/signdata" onClick={() => setVipPopState(false)} className="operate" style={{backgroundImage: 'url(/resources/save/new-vip-operate-bg.png)'}}>前往签到</NavLink>
                      : null}
                    </div>
                  } else if(equityType == 'giftbag') {
                    //专属礼包
                    return <div className="giftbag-module">
                      <div className="container">

                        {packItens?.map((item, index) => <Link to={"/game?id=" + item.appid}>
                          <div className="game-item">
                            <div className="icon"><img src={item.logo} /></div>
                            <div className="name">{item.gamename}</div>
                            <div className="time">会员特权礼包</div>
                          </div>
                        </Link>)}
                      </div>
                      <div className="text-des1">
                        平台会不定期配合游戏商发行一些高价值会员礼包仅供会员免费领取，游戏详情页礼包处会标注该礼包的领取权限，您可以前往游戏详情页查看和领取。
                      </div>
                    </div>
                  } else if(equityType == 'cashcoupon') {
                    //优惠券
                    return <div className="cashcoupon-module">
                      <div className="container">
                        {voucherItems?.map((item, index) => <Link to={"/game?id=" + item.appid}>
                          <div className="game-item">
                            <div className="icon"><img src={item.logo.thumb} /></div>
                            <div className="name">{item.gameName}</div>
                            <div className="time">会员专属代金券</div>
                          </div>
                        </Link>)}
                      </div>
                      <div className="text-des1">
                        平台会不定期配合游戏商发行一些高价值会员代金券仅供会员免费领取，游戏详情页代金券处会标注该代金券的领取权限，您可以前往游戏详情页查看和领取。
                      </div>
                    </div>
                  }
                })()}

                {/*** 下面购买成功后显示 仅四边形 **/}
                {(() => {
                  if(vipInfo.level != 0 && vipInfo?.version == 2 && vipInfo.level == data?.levelId) {
                    if(equityType == 'openCard' || equityType == 'monthly' || equityType == 'weekly') {
                      return <>
                        <div className="statement">
                          <img src="/resources/save/statement-of-interest.png" />
                        </div>
                        <div className="des1">
                          {(() => {
                            if(equityType == 'openCard') {
                              if(vipInfo.level == 1) {
                                return '开通即赠送无门槛代金券，自动到账。开通会员月卡赠送6元无门槛券*1张！代金券仅限0.1折/BT游戏可用';
                                // return '开通即赠送无门槛代金券，自动到账。开通会员月卡赠送6元无门槛券*1张！代金券仅限BT游戏可用';
                              } else if(vipInfo.level == 2) {
                                return '开通即赠送无门槛代金券，自动到账。开通会员季卡赠送6元无门槛券*2张！代金券仅限0.1折/BT游戏可用';
                                // return '开通即赠送无门槛代金券，自动到账。开通会员季卡赠送6元无门槛券*2张！代金券仅限BT游戏可用';
                              } else if(vipInfo.level == 3) {
                                return '开通即赠送无门槛代金券，自动到账。开通会员年卡赠送6元无门槛券*8张！代金券仅限0.1折/BT游戏可用';
                                // return '开通即赠送无门槛代金券，自动到账。开通会员年卡赠送6元无门槛券*8张！代金券仅限BT游戏可用';
                              }
                            } else if(equityType == 'monthly') {
                              return <><p>会员每月1日可免费领取无门槛代金券，会员等级越高，代金券金额越大。
                                次数在下个月1日重置，错过不再补发，有效期7天，领取后可前往我的-代金券页面查看详情，代金券仅限0.1折/BT游戏可用。</p>
                              </>;
                            //   return <><p>会员每月1日可免费领取无门槛代金券，会员等级越高，代金券金额越大。
                            //   次数在下个月1日重置，错过不再补发，有效期7天，领取后可前往我的-代金券页面查看详情，代金券仅限BT游戏可用。</p>
                            // </>;

                            } else if(equityType == 'weekly') {
                              return '代金券每次购买会消耗当周的购买次数，每周仅能购买一次，次数在每周一会重置，代金券有效期7天，购买后可前往我的-代金券页面查看详情，代金券仅限0.1折/BT游戏可用。';
                              // return '代金券每次购买会消耗当周的购买次数，每周仅能购买一次，次数在每周一会重置，代金券有效期7天，购买后可前往我的-代金券页面查看详情，代金券仅限BT游戏可用。';
                            } else if(equityType == 'buySaveCard') {
                              return;
                            } else {
                              return ;
                            }
                          })()}
                        </div>
                      </>
                    }
                  }
                })()}
                {/* <div className="statement">
                  <img src="/resources/save/statement-of-interest.png" />
                </div>
                <div className="des1">
                  {(() => {
                    if(equityType == 'openCard') {
                      return '开通即赠送无门槛代金券，自动到账。开通会员月卡赠送6元无门槛券*1张！';
                    } else if(equityType == 'monthly') {
                      return <><p>会员每月可领取免费无门槛代金券，会员等级越高，代金券金额越大;</p>
                      <p>代金券每月可领取一次，次数在每月1日重置，有效期7天，领取后可前往我的-代金券页面查看详情。</p></>;
                    } else if(equityType == 'weekly') {
                      return '代金券每次购买会消耗当周的购买次数，每周仅能购买一次，次数在每周一会重置，代金券有效期7天，购买后可前往我的-代金券页面查看详情。';
                    } else if(equityType == 'buySaveCard') {
                      return;
                    } else {
                      return ;
                    }
                  })()}
                </div> */}

              </div>
          </div>
        </div>

        <div className="close" onClick={() => setVipPopState(false)}>
          <img src="https://cdn1.oss.wakaifu.com/active/20210401/close.png" />
        </div>
      </div>
    </div>
    : null}

    {/*** 支付 ***/}
    <CSSTransition in={!!pay} timeout={250} unmountOnExit={true}>
      <div className="save-pay">
        <div className="mask" onClick={() => setPay(null)}/>
        <div className="container">
          <div className="title">支付</div>
          <object data={payUrl}
            width="100%"
            height={user.is_oversea == '2' ? '370px' : '200px'}
            className={"ordinary " + (user.is_oversea == '2' ? 'overseas' : '') }
            type="text/html">
          </object>
        </div>
      </div>
    </CSSTransition>

    {/*** 代金券购买 ***/}
    <CSSTransition in={!!voucherPay} timeout={250} unmountOnExit={true}>
      <div className="save-pay">
        <div className="mask" onClick={() => setVoucherPay(false)}/>
        <div className="container">
          <div className="title">支付</div>
          <object data={voucherPayUrl}
            width="100%"
            height={user.is_oversea == '2' ? '370px' : '200px'}
            className={"ordinary " + (user.is_oversea == '2' ? 'overseas' : '') }
            type="text/html">
          </object>
        </div>
      </div>
    </CSSTransition>

    <CSSTransition in={gameAlert} timeout={250} unmountOnExit={true}>
      <div className="game-alert">
        <div className="mask" onClick={() => {
          setGameAlert(false)
        }}/>
        <div className="container">
          <div className="title">以下游戏不可用<img onClick={()=>setGameAlert(false)} className="close" src="resources/community/close.png"></img></div>
          <div className="header">
            <input ref={searchRef} type="text" placeholder='请输入游戏名'/>
            <div className="search" onClick={()=>{
              getNotApplicableList()
            }}>搜索</div>
          </div>
          {notApplicableList?.length === 0 ? <div className="empty"><img src="/resources/empty.png" alt=""/>暂无数据</div> : null}
          <div style={{overflow:'scroll',height:'83%'}}>
            <div className="games" style={{overflow:'scroll'}}>
              {notApplicableList?.map((item)=>{
                return (
                  <div key={item.id} className="item">
                    <img src={item.game_image.thumb} alt="" />
                    <div className="gamename">{item.game_name}</div>
                    <div className="desc">{item.nameRemark}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>

  </div> : null;
};